export function SvgTimerPlay() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <g clipPath="url(#clip0_964_2899)">
        <path
          d="M29.064 18.624L13.1655 29.223C13.0526 29.2981 12.9213 29.3413 12.7858 29.3477C12.6503 29.3542 12.5156 29.3238 12.396 29.2598C12.2764 29.1958 12.1764 29.1005 12.1067 28.9841C12.0369 28.8678 12.0001 28.7346 12 28.599V7.40098C12.0001 7.26532 12.0369 7.13221 12.1067 7.01585C12.1764 6.89948 12.2764 6.8042 12.396 6.74017C12.5156 6.67614 12.6503 6.64574 12.7858 6.65222C12.9213 6.65871 13.0526 6.70182 13.1655 6.77698L29.064 17.376C29.1667 17.4445 29.2509 17.5373 29.3092 17.6461C29.3674 17.755 29.3979 17.8765 29.3979 18C29.3979 18.1234 29.3674 18.245 29.3092 18.3538C29.2509 18.4627 29.1667 18.5555 29.064 18.624Z"
          fill="#FEFEFE"
        />
      </g>
      <defs>
        <clipPath id="clip0_964_2899">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
